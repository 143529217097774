import React, { useEffect } from 'react';

import detectLocale from './detectLocale';

const Redirect = ({ navigate, pageContext: { redirectPage, languages } }) => {
  useEffect(() => {
    const detected = detectLocale(languages);
    const newUrl = `/${detected}${redirectPage}`;

    localStorage.setItem('language', detected);
    navigate(newUrl);
  }, []);

  return <div />;
};

export default Redirect;
