import browserLang from 'browser-lang';

const getDefault = languages => languages.find(language => language.default).locale;

export default languages =>
  localStorage.getItem('language') ||
  browserLang({
    languages: languages.map(language => language.locale),
    fallback: getDefault(languages),
  });
